import React from "react";
import {CantoObject, UiConfig} from "./utils";
import {UiScope} from "@bloomreach/ui-extension";
import {UiDialog} from "./UiDialog";

interface CmsDialogState {
    items: Array<CantoObject>
    env: string
    extensions?: string | undefined
    mdc?: { enabled: boolean, cloudFrontUrl: string, account_ID: string }
}

interface CmsDialogProperties {
    ui: UiScope
}

export default class CmsDialog extends React.Component<CmsDialogProperties, CmsDialogState> {

    constructor(props: CmsDialogProperties) {
        super(props);

        const config: UiConfig = JSON.parse(props.ui.extension.config);

        this.state = {
            items: [],
            env: config.env,
            extensions: config.extensions,
            mdc: config.mdc
        }

        console.log('config', config);
    }

    componentDidMount() {
        this.getInitialItems(this.props.ui).then(items => this.setState({items: items}));
    }

    async getInitialItems(ui: UiScope) {
        try {
            const options = await ui.dialog.options();
            let items = JSON.parse(options.value)
            return items;
        } catch (error: any) {
            console.error('Failed to register extension:', error.message);
            console.error('- error code:', error.code);
        }
        return [];
    }


    render() {
        const {items, env, extensions, mdc} = this.state;
        return (
            <UiDialog key={items.length} items={items}
                      env={env} extensions={extensions} onOk={items => {
                mdc && mdc.enabled ? this.props.ui.dialog.close(items.map(item => {
                    return {...item, mdc: {...mdc}}
                })) : this.props.ui.dialog.close(items);
            }}/>);
    }
}


